import React, { useMemo, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper } from "../../layout/Wrapper"
import { Container } from "../../layout/Container"
import classNames from "classnames"
import SanityBlockContent from "@sanity/block-content-to-react"

import * as styles from "./ProjectPage.module.scss"

import ReactMarkdown from "react-markdown"
import { ListItem } from "../../common/ListItem"

import { arrayofObjectstoCommaSeparatedstring } from "../../../utils/common"
import { HostedEmbed } from "../../common/HostedEmbed"
import { InternalExternalLink } from "../../common/InternalExternalLink"
import { PageBlock } from "../Project/PageBlock"
import { Collapsible } from "../../common/Collapsible"
import { Button } from "../../common/Button"
import { blocksToContent } from "../../../utils/content"
import { MediaQuery, useMediaQuery } from "../../../utils/hooks/mediaQuery"

const ProjectPage = ({
  title,
  _rawBodyIntroduction,
  _rawBody,
  hasCoverImage,
  featuredImage,
  isFeaturedVideoMobile,
  hasFeaturedVideo,
  isMp4Portrait,
  featuredVideo,
  clients,
  services,
  displayedServices,
  years,
  collaborators,
  team,
  links,
  pageBlocks,
}) => {
  // const media = JSON.parse(hostedmedia)
  const isTablet = useMediaQuery(MediaQuery.Tablet)

  const [readMore, setReadMore] = useState(true)

  const readMoreOrLess = () => {
    setReadMore(!readMore)
  }

  const splitBlocks = useMemo(() => {
    return blocksToContent(pageBlocks)
  }, [pageBlocks])

  return (
    <div className={styles.pageContainer}>
      <Container
        className={classNames(styles.projectImage, {
          [styles.cover]: hasCoverImage === true,
          [styles.notCover]: !hasCoverImage,
          [styles.videoMobile]: isFeaturedVideoMobile && isTablet,
        })}
      >
        <Wrapper>
          {hasFeaturedVideo ? (
            <HostedEmbed
              mediaUrl={featuredVideo?.source?.asset?.url}
              placeholderImage={featuredImage}
              isPortrait={isMp4Portrait}
              // className={styles.featuredVideo}
            />
          ) : (
            <GatsbyImage
              image={featuredImage?.image?.asset?.gatsbyImageData}
              alt={featuredImage?.altText}
              loading="eager"
            />
          )}
        </Wrapper>
      </Container>
      <Container className={styles.whiteBackground} padded={false}>
        <Wrapper className={classNames(styles.projectInfo)} narrow>
          <div>
            <h1>{title}</h1>
          </div>
          {years?.length > 0 && (
            <ListItem label={"Year"}>
              <span>{arrayofObjectstoCommaSeparatedstring(years)}</span>
            </ListItem>
          )}

          {clients.length > 0 && (
            <ListItem label={"Client"} topBorder={false}>
              <span>{arrayofObjectstoCommaSeparatedstring(clients)}</span>
            </ListItem>
          )}

          {links && (
            <>
              {links.map((link, index) => {
                return (
                  <ListItem
                    label={link.title}
                    key={`link-${index}`}
                    topBorder={false}
                  >
                    <InternalExternalLink to={link.url}>
                      {link.url}
                    </InternalExternalLink>
                  </ListItem>
                )
              })}
            </>
          )}

          {displayedServices?.length > 0 && (
            <ListItem label={"Services"} topBorder={false}>
              <span>
                {arrayofObjectstoCommaSeparatedstring(displayedServices)}
              </span>
            </ListItem>
          )}
          {team && (
            <ListItem label={"Partners"} topBorder={false}>
              {team}
            </ListItem>
          )}
          {collaborators && (
            <ListItem label={"Collaborators"} topBorder={false}>
              {collaborators}
            </ListItem>
          )}
        </Wrapper>
        {_rawBodyIntroduction?.length > 0 && (
          <div className={classNames(styles.bodyBlock)}>
            <Wrapper className={styles.bodyIntro} narrow>
              <div>
                <SanityBlockContent blocks={_rawBodyIntroduction} />
                {/* <ReactMarkdown>{bodyintro}</ReactMarkdown> */}
              </div>
            </Wrapper>

            {_rawBody?.length > 0 && (
              <>
                <Collapsible open={!readMore} duration={350}>
                  <Wrapper narrow>
                    <div>
                      <SanityBlockContent blocks={_rawBody} />
                      {/* <ReactMarkdown>{body}</ReactMarkdown> */}
                    </div>
                  </Wrapper>
                </Collapsible>
                <Wrapper narrow>
                  <p className={styles.moreLess}>
                    <Button
                      onClick={readMoreOrLess}
                      className={styles.readMore}
                      plain
                    >
                      {readMore ? "Read More" : "Read Less"}
                    </Button>
                  </p>
                </Wrapper>
              </>
            )}
          </div>
        )}
      </Container>

      {/* Page Blocks */}
      {splitBlocks.length > 0 && (
        <Container className={styles.whiteBackground} padded={false}>
          {splitBlocks.map(({ type, portableText, ...rest }, idx) => (
            <PageBlock key={idx} type={type} {...rest}>
              {type === "portableText" && (
                <SanityBlockContent blocks={portableText} />
              )}
            </PageBlock>
          ))}
        </Container>
      )}
    </div>
  )
}

export { ProjectPage }
